import React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from './logo-png.png';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 35,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return <img className={classes.img} src={logo} alt="Logo" />;
};

export default LogoIcon;